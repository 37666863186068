import { constant, flatMap, omit, times, xorWith, xorBy } from 'lodash-es';
import { createSelector } from 'reselect';
import { isDefined } from 'axis-webtools-util';
import { t } from 'app/translate';
import {
    getCurrentAvailableRecordingSolutionComponents,
    getPiaItemsRecord,
    createDeepEqualSelector,
    getCurrentProjectItemsArray,
    getCurrentProjectItemRelationsArray,
    getDevicePowerRequirements,
    getDevicePoeClass,
    shouldCountAsDevice,
    getNrOfChannels,
    getBandwidthStorageEstimateForItems,
    getSelectedRecordingSolutionType,
    calculateTotalsForDevice,
    isAxis,
    getSelectedAxisAcsType,
    getSelectedCenterChoice,
    getItemsWithLicenseRequirement,
} from 'app/modules/common';
import { getRecommendationGraph } from './recommendationEngine';
import type {
    IRecordingSolution,
    IRecordingSolutionItem,
    IDeviceRequirement,
    ISystemConfiguration,
    ISDCardComponent,
} from '../common';
import { DEBUG_MODE, COMPANION_4_PIA_ID, preprocessComponent } from '../common';
import { toRecordingSolutionItem, toRecordingSolution, stringifySystemGraph } from '../converters';
import { getCurrentSolutionWithAdditionalStorage } from '../getRecordingSolution';
import type {
    IPiaItem,
    IPiaRelationReference,
    IPiaSoftware,
    IPiaSystemComponent,
    PiaId,
} from 'app/core/pia';
import { PiaAccessoryCategory } from 'app/core/pia';
import type { Id, IItemEntity, IPersistence } from 'app/core/persistence';
import {
    deviceTypeCheckers,
    getParentId,
    isVirtualCamera,
    needsLicense,
    needsPort,
} from 'app/core/persistence';
import type { IStoreState } from 'app/store';
import { CENTER_LICENSE_PIAID_FIVE_YEAR, CENTER_LICENSE_PIAID_ONE_YEAR } from '../../constants';
import { groupByPiaId } from '../groupByPiaId';

const getRecordingSelector = (state: IStoreState) => state.recordingSelector;

const getAxisComponents = createSelector(
    [getCurrentAvailableRecordingSolutionComponents],
    (components) => components.filter(({ properties }) => isAxis(properties.vendor)),
);

const getS1XComponents = createSelector([getAxisComponents], (components) =>
    components
        .filter(
            ({ category, properties }) =>
                category !== 'recorders2' ||
                properties.series === 'S11' ||
                properties.series === 'S12',
        )
        .map((component) => preprocessComponent(component)),
);

const getS2XComponents = createSelector([getAxisComponents], (components) =>
    components
        .filter(
            ({ category, properties }) =>
                category !== 'recorders2' ||
                properties.series === 'S21' ||
                properties.series === 'S22',
        )
        .map((component) => preprocessComponent(component)),
);

const getCompanionComponents = createSelector([getAxisComponents], (components) => {
    const s30Components = components.filter(
        ({ category, properties }) => category !== 'recorders2' || properties.series === 'S30',
    );

    return s30Components.map((component) => preprocessComponent(component, true));
});

const getS30Components = createSelector([getAxisComponents], (components) => {
    const s30Components = components.filter(
        ({ category, properties }) => category !== 'recorders2' || properties.series === 'S30',
    );

    return s30Components.map((component) => preprocessComponent(component));
});

/** Gets ItemEntity and PiaItem of parent device */
const getParent = (
    item: IPersistence<IItemEntity>,
    itemsArray: IPersistence<IItemEntity>[],
    piaItems: Record<PiaId, IPiaItem>,
): { item: IPersistence<IItemEntity> | undefined; piaItem: IPiaItem | undefined } => {
    const parentItem = itemsArray.find((projectItem) => projectItem._id === getParentId(item));
    const parentPiaItem = parentItem?.productId ? piaItems[parentItem.productId] : undefined;
    return { item: parentItem, piaItem: parentPiaItem };
};
/** Checks if an ItemEntity is a virtual camera */
const isVirtualPiaCamera = (item: IPersistence<IItemEntity>, piaItems: Record<PiaId, IPiaItem>) => {
    if (!item.productId) return;
    const piaItem = piaItems[item.productId];
    return isVirtualCamera(piaItem);
};

/** Checks if a PiaItem is compatible with Companion 4 */
const getIsCompanionCompatible = (piaItem?: IPiaItem) =>
    (piaItem?.relations ?? [])
        .filter(({ relationType }) => relationType === 'compatible')
        .some(({ id }) => id === COMPANION_4_PIA_ID);

const getVirtualChildren = (items: IPersistence<IItemEntity>[], itemId: Id) => {
    const virtualChildren = items.reduce((children, item) => {
        return deviceTypeCheckers.isVirtualProduct(item) && getParentId(item) === itemId
            ? [...children, item]
            : children;
    }, [] as IPersistence<IItemEntity>[]);

    return virtualChildren;
};

/*
 * Get the selected acs type. If no selected, return 'CameraStationPro'
 */
export const getViewingAxisAcsType = createSelector(
    [getRecordingSelector, getSelectedAxisAcsType],
    (recordingSelector, selectedAcsType) => {
        return recordingSelector.recordingViewingAxisAcsType
            ? recordingSelector.recordingViewingAxisAcsType
            : (selectedAcsType ?? 'CameraStationPro');
    },
);

export const getNbrCoreLicensesNeeded = createSelector(
    [getItemsWithLicenseRequirement],
    (items) => {
        return items.reduce((totalCount, { quantity }) => (totalCount += quantity), 0);
    },
);

export const getDeviceRequirements = createSelector(
    [
        getCurrentProjectItemsArray,
        getCurrentProjectItemRelationsArray,
        getBandwidthStorageEstimateForItems,
        getDevicePowerRequirements,
        getDevicePoeClass,
        getPiaItemsRecord,
    ],
    (items, itemRelations, estimates, powerRequirements, poeClasses, piaItems) => {
        const deviceRequirements: IDeviceRequirement[] = [];

        items
            .filter(
                (item) =>
                    needsPort(item, item.productId ? piaItems[item.productId] : undefined) ||
                    needsLicense(item, item.productId ? piaItems[item.productId] : undefined) ||
                    isVirtualPiaCamera(item, piaItems),
            )
            .forEach((item) => {
                // figure out the accessory items
                const accessoryItems = itemRelations
                    .filter(
                        (relation) =>
                            relation.relationType === 'accessory' && relation.parentId === item._id,
                    )
                    .map((relation) => items.find(({ _id }) => _id === relation.childId));

                // create a flat list of all accessories
                const accessories = flatMap(accessoryItems, (accessoryItem) =>
                    accessoryItem && accessoryItem.productId
                        ? times(accessoryItem.quantity, constant(piaItems[accessoryItem.productId]))
                        : [],
                );

                const piaItem = item.productId
                    ? (piaItems[item.productId] as IPiaSystemComponent & IPiaSoftware)
                    : undefined;
                const includedLicenses = piaItem ? piaItem.properties.includedLicensesCount : 0;

                const licenseCount = needsLicense(item, piaItem) && !includedLicenses ? 1 : 0;
                const channelCount = shouldCountAsDevice(item, piaItem)
                    ? getNrOfChannels(item, items, piaItems)
                    : 0;
                const estimate = estimates[item._id] ?? {
                    total: { storageInMB: 0, bandwidthInBps: 0 },
                };

                const itemIsVirtualCamera = isVirtualCamera(piaItem);
                const parent = getParent(item, items, piaItems);
                const parentDeviceName = parent.item?.name;
                const parentDeviceModelName = parent.piaItem?.name;
                const quantity = itemIsVirtualCamera ? (parent.item?.quantity ?? 1) : item.quantity;

                const storage = estimate.total.storageInMB / quantity;
                const bandwidth = estimate.total.bandwidthInBps / quantity;
                const power = powerRequirements[item._id] ?? 0;
                const poeClass = poeClasses[item._id] ?? 'NoPoE';

                // Virtual cameras don't contain companion relation themselves.
                const isCompanionCompatible = getIsCompanionCompatible(
                    itemIsVirtualCamera ? parent.piaItem : piaItem,
                );
                // If device has virtual children we need to take their storage into account.
                const virtualChildren = getVirtualChildren(items, item._id);
                const totalStorageIncludingVirtualChildren =
                    virtualChildren.length > 0
                        ? calculateTotalsForDevice(item._id, virtualChildren, estimates)
                        : undefined;

                for (let i = 0; i < quantity; i++) {
                    deviceRequirements.push({
                        itemId: item._id,
                        productId: piaItem?.id || undefined,
                        name: `${item.name} ${quantity > 1 ? `#${i + 1}` : ''}`,
                        modelName: piaItem?.name || undefined,
                        storage,
                        bandwidth,
                        licenseCount,
                        channelCount,
                        poeClass,
                        power,
                        isCompanionCompatible,
                        accessories,
                        isVirtualCamera: itemIsVirtualCamera,
                        totalStorageIncludingVirtual: totalStorageIncludingVirtualChildren?.storage,
                        parentDeviceName,
                        parentDeviceModelName,
                        virtualChildren,
                    });
                }
            });
        return deviceRequirements;
    },
);

export const getDeviceRequirementsWithoutName = createDeepEqualSelector(
    [getDeviceRequirements],
    (devices) => devices.map((device) => omit(device, 'name')),
);

const getS1XRecommendation = createDeepEqualSelector(
    [getDeviceRequirements, getS1XComponents, getViewingAxisAcsType, getSelectedCenterChoice],
    getRecommendationGraph,
);

const getS2XRecommendation = createDeepEqualSelector(
    [getDeviceRequirements, getS2XComponents, getViewingAxisAcsType, getSelectedCenterChoice],
    getRecommendationGraph,
);

const areDevicesCompanionCompatible = (devices: IDeviceRequirement[]) =>
    devices.every(({ isCompanionCompatible }) => isCompanionCompatible);

const getCompanionRecommendation = createDeepEqualSelector(
    [getDeviceRequirements, getCompanionComponents, getViewingAxisAcsType, getSelectedCenterChoice],
    (devices, components, selectedAcsType, selectedCenterChoice) =>
        areDevicesCompanionCompatible(devices)
            ? getRecommendationGraph(devices, components, selectedAcsType, selectedCenterChoice)
            : null,
);

const getS30Recommendation = createDeepEqualSelector(
    [getDeviceRequirements, getS30Components, getViewingAxisAcsType, getSelectedCenterChoice],
    getRecommendationGraph,
);

const byCardSize = (a: IPiaSystemComponent, b: IPiaSystemComponent) =>
    (a.properties.memoryCardSize ?? 0) - (b.properties.memoryCardSize ?? 0);

export const getCompanionSDCardsNeeded = createDeepEqualSelector(
    [getCompanionRecommendation, getDeviceRequirements, getCompanionComponents],
    (companionVMSRecommendation, devices, components) => {
        // SD-card solution is only valid if Companion VMS solution is valid
        if (companionVMSRecommendation === null) {
            return [];
        }

        const sortedSDCards = components
            .filter(
                (component) =>
                    component.category === PiaAccessoryCategory.STORAGE &&
                    component.properties.memoryCardSize,
            )
            .sort(byCardSize);

        if (sortedSDCards.length < 1) {
            return [];
        }

        const sdCardComponents: ISDCardComponent[] = [];
        // Filter out devices that does not need SD-Cards (do not require storage),
        // since they are not affected by an SD-card recording solution
        const storageDevices = devices.filter((device) => device.storage > 0);
        for (const device of storageDevices) {
            const sdCard = getSDCardForDevice(device, sortedSDCards);
            // If no card found for a single device, the sd-card solution is not valid.
            // a virtual camera doesn't need to have a sdCard. Parent's sdCard is used if it exists
            if (device.isVirtualCamera) {
                continue;
            }
            if (sdCard === null) {
                return [];
            }
            const existingCard = sdCardComponents.find(
                (card) => card?.component.id === sdCard.component.id,
            );
            // If card already added, update quantity and array of devices that should have this card as accessory,
            // or add it as a new card with quantity 1, and the deviceId.
            if (existingCard) {
                existingCard.quantity += 1;
                existingCard.deviceIds.push(device.itemId);
            } else {
                sdCardComponents.push({ ...sdCard, quantity: 1, deviceIds: [device.itemId] });
            }
        }

        return sdCardComponents;
    },
);

/**
 * Get first sd-card applicable for the device, or null if no match
 * @param device - device in need of sd-card
 * @param sdCards - Available sd-cards ordered by min - max capacity
 */
const getSDCardForDevice = (device: IDeviceRequirement, sdCards: IPiaSystemComponent[]) => {
    for (const sdCard of sdCards) {
        // It the device includes virtual products we need to take storage for virtual device into account
        const deviceStorage = device.totalStorageIncludingVirtual
            ? device.totalStorageIncludingVirtual
            : device.storage;
        if (
            sdCard.properties.memoryCardSize &&
            sdCard.properties.memoryCardSize * 1000 > deviceStorage &&
            isImplicitlyCompatible(device.productId, sdCard.relations)
        ) {
            return { component: sdCard, quantity: 1 };
        }
    }
    return null;
};

const isImplicitlyCompatible = (
    productId: PiaId | undefined,
    relations: IPiaRelationReference[],
) => {
    return relations.some(
        ({ id, relationType }) => relationType === 'implicitlycompatible' && id === productId,
    );
};

/**
 * How much storage do all sd-cards provide
 */
export const getSDCardsStorageInGB = createSelector([getCompanionSDCardsNeeded], (sdCards) => {
    return sdCards.reduce((acc, current) => {
        return acc + current.quantity * current.component.properties.memoryCardSize! * 1000;
    }, 0);
});

export const getRecommendations = createSelector(
    [
        getS1XRecommendation,
        getS2XRecommendation,
        getCompanionRecommendation,
        getCompanionSDCardsNeeded,
        getS30Recommendation,
        getViewingAxisAcsType,
    ],
    (
        s1XRecommendation,
        s2XRecommendation,
        companionRecommendation,
        SDCardsNeeded,
        s30Recommendation,
        selectedType,
    ): IRecordingSolution[] => {
        if (DEBUG_MODE) {
            console.info('S1X Recommendation');
            console.info(stringifySystemGraph(s1XRecommendation));

            console.info('S2X Recommendation');
            console.info(stringifySystemGraph(s2XRecommendation));

            console.info('Companion Recommendation');
            console.info(stringifySystemGraph(companionRecommendation));
        }

        const s11Summary = toRecordingSolution(
            t.s11RecordingSolutionHeading,
            selectedType === 'CameraStation5'
                ? t.s11RecordingSolutionDescription
                : t.s11RecordingSolutionDescriptionPro,
            s1XRecommendation,
            selectedType === 'CameraStation5' ? 'AxisS1X' : 'AxisS1XPro',
        );
        const s22Summary = toRecordingSolution(
            t.s22RecordingSolutionHeading,
            selectedType === 'CameraStation5'
                ? t.s22RecordingSolutionDescription
                : t.s22RecordingSolutionDescriptionPro,
            s2XRecommendation,
            selectedType === 'CameraStation5' ? 'AxisS2X' : 'AxisS2XPro',
        );
        const companionSummary = toRecordingSolution(
            selectedType === 'CameraStation5'
                ? t.companionRecordingSolutionHeading
                : t.companionRecordingSolutionHeadingEdge,
            selectedType === 'CameraStation5'
                ? t.companionSolutionDescription
                : t.companionSolutionDescriptionEdge,
            companionRecommendation,
            'AxisCompanionS30',
        );

        const s30Summary = toRecordingSolution(
            t.s30RecordingSolutionHeading,
            selectedType === 'CameraStation5'
                ? t.s30RecordingSolutionDescription
                : t.s30RecordingSolutionDescriptionPro,
            s30Recommendation,
            selectedType === 'CameraStation5' ? 'AxisS30' : 'AxisS30Pro',
        );

        // If a valid companionSolution and SD-cards in pia exists - get sd-card solution
        // with same calculated switches from the S11-solution
        let companionSDCardSummary: IRecordingSolution | null = null;
        if (s11Summary && SDCardsNeeded.length > 0) {
            const companionSDCardSolutionItems = getCompanionSDCardSolutionItems(
                s11Summary,
                s1XRecommendation,
                SDCardsNeeded,
            );
            companionSDCardSummary = {
                id: 'AxisCompanionSDCard',
                name:
                    selectedType === 'CameraStation5'
                        ? t.companionRecordingSolutionHeading
                        : t.companionRecordingSolutionHeadingEdge,
                description:
                    selectedType === 'CameraStation5'
                        ? t.companionSDCardSolutionDescription
                        : t.companionSDCardSolutionDescriptionEdge,
                items: companionSDCardSolutionItems,
            };
        }

        const recommendations = [
            selectedType === 'CameraStation5' ? null : companionSummary,
            selectedType === 'CameraStation5' ? null : companionSDCardSummary,
            s11Summary,
            s22Summary,
            s30Summary,
        ].filter(isDefined);
        return recommendations;
    },
);

/**
 * Gets the items to be included in the Companion SD-card solution e.g switches and sd-cards.
 * @param s11Solution - A valid s11 solution
 * @param s11Recommendation - The s11 recommendation
 * @param companionSDCards - The SD-card for each device, to be included in the solution
 */
export const getCompanionSDCardSolutionItems = (
    s11Solution: IRecordingSolution,
    s11Recommendation: ISystemConfiguration | null,
    companionSDCards: ISDCardComponent[],
): IRecordingSolutionItem[] => {
    const switchIds: PiaId[] =
        s11Recommendation?.switches.map(({ networkSwitch }) => networkSwitch.id) ?? [];
    const switchItems = s11Solution.items.filter((item) => switchIds.includes(item.piaId));
    const centerLicenses = s11Solution.items.filter(
        (item) =>
            item.piaId === CENTER_LICENSE_PIAID_ONE_YEAR ||
            item.piaId === CENTER_LICENSE_PIAID_FIVE_YEAR,
    );
    const sdCardItems = toRecordingSolutionItem(companionSDCards);
    // The order here will determine how items are presented in the solution card.
    return sdCardItems.concat(switchItems).concat(centerLicenses);
};

/**
 * if the user selected solution is valid for selected products
 * the solution is selected
 */
const getSelectedRecommendation = createSelector(
    [getRecommendations, getCurrentSolutionWithAdditionalStorage, getSelectedRecordingSolutionType],
    (recommendations, currentSelection, selectedRecordingSolutionType) => {
        if (currentSelection.length !== 0) {
            const selectedRecommendation = recommendations.filter(
                (recommendation) => recommendation.id === selectedRecordingSolutionType,
            );
            return selectedRecommendation.find((recommendation) => {
                // currentSelection is an array of IRecordingSolutionItem group by piaId
                // To compare recommendationItems also needs to be group by piaId.
                const recommendationItemsGroupByPiaId = groupByPiaId(recommendation.items);
                return (
                    xorWith(
                        recommendationItemsGroupByPiaId,
                        currentSelection,
                        (a, b) =>
                            a.piaId === b.piaId &&
                            a.quantity === b.quantity &&
                            xorBy(a.accessories, b.accessories, (accessory) =>
                                [accessory.quantity, accessory.piaId].join('x'),
                            ).length === 0,
                    ).length === 0
                );
            });
        }
    },
);

/*
 * get the id of the currently selected recommendation
 */
export const getSelectedRecommendationId = createSelector(
    [getSelectedRecommendation],
    (recommendation) => recommendation?.id,
);
