import * as React from 'react';
import { connect } from 'react-redux';
import { ServiceLocator } from 'app/ioc';
import type { IStoreState } from 'app/store';
import type { Id } from 'app/core/persistence';
import { Border, Box, Checkbox, Heading, Spacer, Text } from 'app/components';
import { t } from 'app/translate';
import { ApplicationGrid } from './ApplicationGrid';
import type { IApplicationItem } from 'app/modules/common';
import {
    getIsPartnerAcapAdded,
    getMultipleApplicationsSelected,
    getNonPersistedCompatibleApplications,
    getPersistedApplications,
    getSelectedMultiAppsELicensePreferred,
    getShowReadMoreModal,
    getVisibleApplications,
} from '../selectors';
import { ReadMoreModal } from './ReadMoreModal';
import type { IReadMore } from '../models';
import { AddonSelectorActionService } from '../services';
import { ApplicationWarning } from './ApplicationWarning';

interface IApplicationSelectorContainerOwnProps {
    deviceItemId: Id;
}

interface IApplicationSelectorState {
    disableSlider: boolean;
}

interface IApplicationSelectorContainerProps extends IApplicationSelectorContainerOwnProps {
    deviceItemId: Id;
    visibleApplications: IApplicationItem[];
    selectedApplications: IApplicationItem[];
    showPerformanceWarning: boolean;
    showPartnerWarning: boolean;
    isELicensePreferred: boolean;
    initialELicensePreferred: boolean | undefined;
    nonSelectedApplications: IApplicationItem[];
    readMoreModal: IReadMore;
}

const mapStateToProps = (
    storeState: IStoreState,
    ownProps: IApplicationSelectorContainerOwnProps,
): IApplicationSelectorContainerProps => {
    return {
        deviceItemId: ownProps.deviceItemId,
        visibleApplications: getVisibleApplications(storeState, ownProps.deviceItemId),
        selectedApplications: getPersistedApplications(storeState, ownProps.deviceItemId),
        showPerformanceWarning: getMultipleApplicationsSelected(storeState, ownProps.deviceItemId),
        showPartnerWarning: getIsPartnerAcapAdded(storeState, ownProps.deviceItemId),
        isELicensePreferred: storeState.addonSelector.isELicensePreferred,
        initialELicensePreferred: getSelectedMultiAppsELicensePreferred(
            storeState,
            ownProps.deviceItemId,
        ),
        nonSelectedApplications: getNonPersistedCompatibleApplications(
            storeState,
            ownProps.deviceItemId,
        ),
        readMoreModal: getShowReadMoreModal(storeState),
    };
};

class ApplicationSelectorContainer extends React.Component<
    IApplicationSelectorContainerProps,
    IApplicationSelectorState
> {
    private addonSelectorActionService: AddonSelectorActionService;

    constructor(props: IApplicationSelectorContainerProps) {
        super(props);
        this.addonSelectorActionService = ServiceLocator.get(AddonSelectorActionService);
        this.state = { disableSlider: false };
    }

    public componentDidMount() {
        this.setInitialELicense();
    }

    public componentWillUnmount() {
        //reset filter since filter shared with other products
        this.addonSelectorActionService.resetPartnerProductFilter();
    }

    public componentDidUpdate(prevProps: IApplicationSelectorContainerProps) {
        // DeviceId changed
        if (this.props.deviceItemId !== prevProps.deviceItemId) {
            this.setInitialELicense();
        }

        // When application is added, check if it was made by undo/redo out of view and handle it appropriately
        if (this.props.selectedApplications !== prevProps.selectedApplications) {
            this.handleUndoApplication();
        }

        // Re-enable slider when eLicense has finished toggling
        if (this.props.isELicensePreferred !== prevProps.isELicensePreferred) {
            this.setState({ disableSlider: false });
        }
    }

    private toggleELicense(isELicensePreferred: boolean) {
        // Disable slider during toggling
        this.setState({ disableSlider: true });
        this.addonSelectorActionService.toggleELicense(
            this.props.deviceItemId,
            isELicensePreferred,
        );
    }

    /** TODO: Change this to a selector or similar cached solution that
     *  checks if any third party acap is available for parent device
     * https://jira.se.axis.com/browse/WT-5716 */
    private hasCompatiblePartnerApplications = true;

    public render() {
        const performanceTexts = {
            heading: t.applicationSelectorPerformanceHeader,
            text: t.applicationSelectorPerformanceText,
        };

        const partnerTexts = {
            heading: t.applicationSelectorPartnerHeader,
            text: t.applicationSelectorPartnerText,
        };

        const content = [];
        if (this.props.showPerformanceWarning) {
            content.push(performanceTexts);
        }
        if (this.props.showPartnerWarning) {
            content.push(partnerTexts);
        }

        return (
            <Box direction="column" spacing="base">
                {(this.props.showPerformanceWarning || this.props.showPartnerWarning) && (
                    <Border color={'grey3'} radius={'8px'}>
                        <ApplicationWarning
                            content={content}
                            level="info"
                            color="blue"
                            testId="application_selector_alert"
                        />
                    </Border>
                )}
                {this.props.readMoreModal.show && this.props.readMoreModal.application && (
                    <ReadMoreModal
                        application={this.props.readMoreModal.application}
                        parentDeviceId={this.props.deviceItemId}
                    />
                )}
                {this.props.visibleApplications.length > 0 && (
                    <>
                        <Box justifyContent="between" alignItems="center">
                            <Heading>{t.selectedApplications}</Heading>
                            <Checkbox
                                disabled={this.state.disableSlider}
                                slider
                                selected={this.props.isELicensePreferred}
                                labelLeft={true}
                                onChange={(val) => this.toggleELicense(val)}
                            >
                                {t.applicationSelectorPreferELicense}
                            </Checkbox>
                        </Box>
                        {this.props.selectedApplications.length > 0 ? (
                            <ApplicationGrid
                                parentId={this.props.deviceItemId}
                                applications={this.props.selectedApplications}
                                section="selected"
                            />
                        ) : (
                            <Text italic>{t.noApplicationsSelected}</Text>
                        )}
                    </>
                )}
                {!this.props.visibleApplications.length &&
                    !this.hasCompatiblePartnerApplications && (
                        <Text>{t.deviceDetailsApplicationsNoApplications}</Text>
                    )}
                {(this.props.nonSelectedApplications.length ||
                    this.hasCompatiblePartnerApplications) && (
                    <>
                        <Heading>{t.suggestedApplications}</Heading>
                        <ApplicationGrid
                            parentId={this.props.deviceItemId}
                            applications={this.props.nonSelectedApplications}
                            section="suggested"
                        />
                    </>
                )}
                <Spacer customSpacing="150px" />
            </Box>
        );
    }

    /**
     * Sets prefer eLicense to false if device has multi-version app(s) with paper license
     */
    private setInitialELicense() {
        const { initialELicensePreferred, isELicensePreferred } = this.props;
        const newSetting = initialELicensePreferred !== undefined ? initialELicensePreferred : true;
        if (newSetting !== isELicensePreferred) {
            this.addonSelectorActionService.setELicensePreferred(newSetting);
        }
    }

    /**
     * Toggles prefer eLicense if an application was added that is not currently visible.
     */
    private handleUndoApplication() {
        const { isELicensePreferred, initialELicensePreferred } = this.props;

        if (
            initialELicensePreferred !== undefined &&
            initialELicensePreferred !== isELicensePreferred
        ) {
            this.addonSelectorActionService.setELicensePreferred(initialELicensePreferred);
        }
    }
}

export const ApplicationSelector = connect(mapStateToProps)(ApplicationSelectorContainer);
