import { isDefined } from 'axis-webtools-util';
import { flatMap } from 'lodash-es';
import { createSelector } from 'reselect';
import type { IProduct } from 'app/modules/common';
import {
    getSelectedAxisAcsType,
    getSelectedCenterChoice,
    getSelectedRecordingSolutionType,
    getProductsWithPrices,
    CategoryEnum,
    getPiaItemsRecord,
    VendorEnum,
    CameraUspService,
} from 'app/modules/common';
import { ProposalCategory } from './ProposalCategory.enum';
import type { PiaId, IPiaSensorDevice } from 'app/core/pia';
import {
    PiaItemRecorderCategory,
    PiaAccessoryCategory,
    PiaItemSoftwareCategory,
    PiaItemCameraCategory,
    PiaItemPacCategory,
    PiaItemMainUnitCategory,
    PiaItemSensorUnitCategory,
} from 'app/core/pia';
import { ServiceLocator } from 'app/ioc';
import { nameComparator } from 'app/utils';

const recordingCategoryMapping: Record<string, string> = {
    [PiaItemRecorderCategory.RECORDERS2]: '1', // servers
    [PiaItemRecorderCategory.DESKTOPTERMINALS]: '2', // terminals
    [PiaAccessoryCategory.NETWORKSWITCHES]: '3', // switches
    [PiaItemSoftwareCategory.VMS]: '4', // licenses
};

const cameraCategoryMapping: Record<string, string> = {
    [PiaItemCameraCategory.FIXEDDOME]: '1',
    [PiaItemCameraCategory.FIXED]: '2',
    [PiaItemCameraCategory.PTZ]: '3',
    [PiaItemCameraCategory.COMPLETEMODULAR]: '4',
    [PiaItemCameraCategory.CAMERAEX]: '5',
    [PiaItemCameraCategory.THERMAL]: '6',
    [PiaItemMainUnitCategory.MAINUNIT]: '7',
    [PiaItemSensorUnitCategory.SENSORUNIT]: '8',
    [PiaItemPacCategory.DOORSTATIONS]: '9',
};

export const recordingSolutionSort = (a: IProduct, b: IProduct) => {
    if (!a.piaCategory || !b.piaCategory || !recordingCategoryMapping[a.piaCategory]) {
        return nameComparator(a, b);
    }

    return (
        recordingCategoryMapping[a.piaCategory].localeCompare(
            recordingCategoryMapping[b.piaCategory],
        ) || nameComparator(a, b)
    );
};

export const cameraSort = (a: IProduct, b: IProduct) => {
    if (b.piaCategory && (!a.piaCategory || !cameraCategoryMapping[a.piaCategory])) {
        return -1;
    }

    if (a.piaCategory && (!b.piaCategory || !cameraCategoryMapping[b.piaCategory])) {
        return 1;
    }

    if (!a.piaCategory || !b.piaCategory) {
        return nameComparator(a, b);
    }

    return (
        cameraCategoryMapping[a.piaCategory].localeCompare(cameraCategoryMapping[b.piaCategory]) ||
        nameComparator(a, b)
    );
};

export const getProposalDeviceSummary = createSelector(
    [
        getProductsWithPrices,
        getSelectedRecordingSolutionType,
        getSelectedCenterChoice,
        getSelectedAxisAcsType,
    ],
    (products, solutionType, centerChoice, selectedAcsType) => {
        const productsToShow = products
            .filter((product) => product.vendor === VendorEnum.Axis)
            .reduce(
                (summaryRecord, product) => {
                    switch (product.category) {
                        case CategoryEnum.Camera:
                        case CategoryEnum.FSeries:
                        case CategoryEnum.DoorStation:
                        case CategoryEnum.AnalogCamera:
                            summaryRecord[ProposalCategory.Cameras] = [
                                ...(summaryRecord[ProposalCategory.Cameras] ?? []),
                                product,
                            ];
                            break;
                        case CategoryEnum.RecordingAndNetwork:
                            summaryRecord[ProposalCategory.RecordingSolution] = [
                                ...(summaryRecord[ProposalCategory.RecordingSolution] ?? []),
                                product,
                            ];
                            break;
                        case CategoryEnum.Encoder:
                        case CategoryEnum.Decoder:
                        case CategoryEnum.Speaker:
                        case CategoryEnum.RadarDetector:
                        case CategoryEnum.Pac:
                        case CategoryEnum.PeopleCounters:
                        case CategoryEnum.Wearables:
                        case CategoryEnum.DoorControllers:
                        case CategoryEnum.Alerters:
                        case CategoryEnum.ConnectivityDevice:
                        case CategoryEnum.PagingConsole:
                            summaryRecord[ProposalCategory.Other] = [
                                ...(summaryRecord[ProposalCategory.Other] ?? []),
                                product,
                            ];
                            break;
                        case CategoryEnum.Software:
                        case CategoryEnum.Accessory:
                            if (product.piaCategory === 'acap' || product.piaCategory === 'ams') {
                                summaryRecord[ProposalCategory.Software] = [
                                    ...(summaryRecord[ProposalCategory.Software] ?? []),
                                    product,
                                ];
                            }
                            break;
                    }
                    return summaryRecord;
                },
                {} as Record<ProposalCategory, IProduct[]>,
            );

        productsToShow[ProposalCategory.Software]?.sort(nameComparator);
        productsToShow[ProposalCategory.RecordingSolution]?.sort(recordingSolutionSort);
        productsToShow[ProposalCategory.Cameras]?.sort(cameraSort);
        productsToShow[ProposalCategory.Other]?.sort(nameComparator);
        const recorderWithAcs =
            solutionType === 'AxisS30'
                ? true
                : productsToShow[ProposalCategory.RecordingSolution]?.find(
                      (product) =>
                          selectedAcsType === 'CameraStation5' &&
                          product.piaCategory === PiaItemRecorderCategory.RECORDERS2 &&
                          !product.name.startsWith('AXIS S3'),
                  );

        const recorderWithAcsPro =
            solutionType === 'AxisS30Pro'
                ? true
                : selectedAcsType === 'CameraStationPro' &&
                  productsToShow[ProposalCategory.RecordingSolution]?.find(
                      (product) =>
                          product.piaCategory === PiaItemRecorderCategory.RECORDERS2 &&
                          !product.name.startsWith('AXIS S3'),
                  );
        const recorderWithAcc =
            solutionType === 'AxisS30' || solutionType === 'AxisS30Pro'
                ? false
                : productsToShow[ProposalCategory.RecordingSolution]?.find(
                      (product) =>
                          product.piaCategory === PiaItemRecorderCategory.RECORDERS2 &&
                          product.name.startsWith('AXIS S3'),
                  ) || solutionType === 'AxisCompanionSDCard';

        if (recorderWithAcc) {
            productsToShow[ProposalCategory.Software] = [
                createAccProduct(),
                ...(productsToShow[ProposalCategory.Software] ?? []),
            ];
        }

        if (recorderWithAcs) {
            productsToShow[ProposalCategory.Software] = [
                createAcsProduct(),
                ...(productsToShow[ProposalCategory.Software] ?? []),
            ];
        }

        if (recorderWithAcsPro) {
            productsToShow[ProposalCategory.Software] = [
                createAcsProProduct(),
                ...(productsToShow[ProposalCategory.Software] ?? []),
            ];
        }

        if (
            (centerChoice === 'Center1year' || centerChoice === 'Center5years') &&
            selectedAcsType === 'CameraStationPro'
        ) {
            productsToShow[ProposalCategory.Software] = [
                ...(productsToShow[ProposalCategory.Software] ?? []),
                createAcsCenterProduct(),
            ];
        }

        const hasCloudStorage = products.some((product) => product.name.includes('Cloud Storage'));
        if (hasCloudStorage) {
            productsToShow[ProposalCategory.Software] = [
                ...(productsToShow[ProposalCategory.Software] ?? []),
                createCloudStorageProduct(),
            ];
        }

        return [
            {
                proposalCategory: ProposalCategory.Software,
                products: productsToShow[ProposalCategory.Software] ?? [],
            },
            {
                proposalCategory: ProposalCategory.Cameras,
                products: productsToShow[ProposalCategory.Cameras] ?? [],
            },
            {
                proposalCategory: ProposalCategory.RecordingSolution,
                products: productsToShow[ProposalCategory.RecordingSolution] ?? [],
            },
            {
                proposalCategory: ProposalCategory.Other,
                products: productsToShow[ProposalCategory.Other] ?? [],
            },
        ];
    },
);

export const getProductTotalPrice = createSelector([getProductsWithPrices], (products) => {
    return products
        .filter(
            (product) =>
                product.category !== CategoryEnum.InstallationService &&
                product.category !== CategoryEnum.Accessory &&
                product.category !== CategoryEnum.Software,
        )
        .reduce(
            (totalPrice, product) => (totalPrice += (product.quotePrice ?? 0) * product.quantity),
            0,
        );
});

export const getAccessoriesTotalPrice = createSelector([getProductsWithPrices], (products) => {
    return products
        .filter((product) => product.category === CategoryEnum.Accessory)
        .reduce(
            (totalPrice, product) => (totalPrice += (product.quotePrice ?? 0) * product.quantity),
            0,
        );
});

export const getSoftwareTotalPrice = createSelector([getProductsWithPrices], (products) => {
    return products
        .filter((product) => product.category === CategoryEnum.Software)
        .reduce(
            (totalPrice, product) => (totalPrice += (product.quotePrice ?? 0) * product.quantity),
            0,
        );
});

export const getInstallationServicesPrice = createSelector([getProductsWithPrices], (products) => {
    return products
        .filter((product) => product.category === CategoryEnum.InstallationService)
        .reduce(
            (totalPrice, product) => (totalPrice += (product.quotePrice ?? 0) * product.quantity),
            0,
        );
});

export const getProductUsps = createSelector(
    [getPiaItemsRecord, getProductsWithPrices],
    (piaItems, products) => {
        const uspService = ServiceLocator.get(CameraUspService);
        return products.reduce(
            (productUsps, product) => {
                if (product.piaId) {
                    const usps = flatMap(
                        uspService.getUsps([piaItems[product.piaId] as IPiaSensorDevice]),
                    )
                        .filter(isDefined)
                        .filter((usp) => usp.length > 1);
                    productUsps[product.piaId] = usps;
                }

                return productUsps;
            },
            {} as Record<PiaId, string[]>,
        );
    },
);

/**
 * Axis Camera Station is a special case.
 * It doesn't exist in PiaRecords and recorders
 * have no relations to it. Thus we need to create
 * its own IProduct object manually.
 */
const createAcsProduct = (): IProduct => {
    return {
        id: '',
        name: 'AXIS Camera Station',
        partNumber: '',
        piaId: 63452,
        piaCategory: PiaItemSoftwareCategory.VMS,
        quantity: 1,
        vendor: VendorEnum.Axis,
        category: CategoryEnum.Software,
        discontinued: false,
    };
};

/**
 * Axis Camera Station Pro is a special case.
 * It doesn't exist in PiaRecords and recorders
 * have no relations to it. Thus we need to create
 * its own IProduct object manually.
 */
const createAcsProProduct = (): IProduct => {
    return {
        id: '',
        name: 'AXIS Camera Station Pro',
        partNumber: '',
        piaId: 94521,
        piaCategory: PiaItemSoftwareCategory.VMS,
        quantity: 1,
        vendor: VendorEnum.Axis,
        category: CategoryEnum.Software,
        discontinued: false,
    };
};

/**
 * Axis Camera Station Center is a special case.
 * It doesn't exist in PiaRecords and recorders
 * have no relations to it. Thus we need to create
 * its own IProduct object manually.
 */
const createAcsCenterProduct = (): IProduct => {
    return {
        id: '',
        name: 'AXIS Camera Station Center',
        partNumber: '',
        piaId: 96248,
        piaCategory: PiaItemSoftwareCategory.VMS,
        quantity: 1,
        vendor: VendorEnum.Axis,
        category: CategoryEnum.Software,
        discontinued: false,
    };
};

/**
 * Axis Companion is a special case.
 * It doesn't exist in PiaRecords and recorders
 * have no relations to it. Thus we need to create
 * its own IProduct object manually.
 */
const createAccProduct = (): IProduct => {
    return {
        id: '',
        name: 'AXIS Camera Station Edge',
        partNumber: '',
        piaId: 96263,
        piaCategory: PiaItemSoftwareCategory.VMS,
        quantity: 1,
        vendor: VendorEnum.Axis,
        category: CategoryEnum.Software,
        discontinued: false,
    };
};

/**
 * Creates a cloud storage product.
 */
const createCloudStorageProduct = (): IProduct => {
    return {
        id: '',
        name: 'AXIS Camera Station Cloud Storage',
        partNumber: '',
        piaId: 97928,
        piaCategory: PiaItemSoftwareCategory.VMS,
        quantity: 1,
        vendor: VendorEnum.Axis,
        category: CategoryEnum.Software,
        discontinued: false,
    };
};
